import "./src/styles/global.css"
import "@fontsource/montserrat/"
import "@fontsource/montserrat/300.css"
import "@fontsource/montserrat/600.css"
import "@fontsource/montserrat/700.css"


export const onInitialClientRender = () => {
    setTimeout(function () {
        document.getElementById("___loader").style.display = "none"
    }, 1000)
}

export const onPreRouteUpdate = () => {
    document.getElementById('___loader').style.display = "flex";
    document.getElementById('___loader').style.opacity = 1;
}

export const onRouteUpdate = () => {
    setTimeout(() => {
        document.getElementById('___loader').style.opacity = 0;
    }, 650);
    setTimeout(() => {
        document.getElementById('___loader').style.display = "none";
    }, 1000);
}