exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-digital-events-js": () => import("./../../../src/pages/digital-events.js" /* webpackChunkName: "component---src-pages-digital-events-js" */),
  "component---src-pages-gucci-experience-reel-js": () => import("./../../../src/pages/gucci-experience-reel.js" /* webpackChunkName: "component---src-pages-gucci-experience-reel-js" */),
  "component---src-pages-highlights-js": () => import("./../../../src/pages/highlights.js" /* webpackChunkName: "component---src-pages-highlights-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-phygital-experiences-js": () => import("./../../../src/pages/phygital-experiences.js" /* webpackChunkName: "component---src-pages-phygital-experiences-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-valentino-insights-js": () => import("./../../../src/pages/valentino-insights.js" /* webpackChunkName: "component---src-pages-valentino-insights-js" */),
  "component---src-pages-valentino-insights-reel-js": () => import("./../../../src/pages/valentino-insights-reel.js" /* webpackChunkName: "component---src-pages-valentino-insights-reel-js" */),
  "component---src-pages-valentino-insights-roman-palazzo-art-residency-js": () => import("./../../../src/pages/valentino-insights-roman-palazzo-art-residency.js" /* webpackChunkName: "component---src-pages-valentino-insights-roman-palazzo-art-residency-js" */),
  "component---src-pages-valentino-insights-roman-palazzo-reel-js": () => import("./../../../src/pages/valentino-insights-roman-palazzo-reel.js" /* webpackChunkName: "component---src-pages-valentino-insights-roman-palazzo-reel-js" */),
  "component---src-pages-vertual-reel-js": () => import("./../../../src/pages/vertual-reel.js" /* webpackChunkName: "component---src-pages-vertual-reel-js" */),
  "component---src-pages-virtual-fashion-js": () => import("./../../../src/pages/virtual-fashion.js" /* webpackChunkName: "component---src-pages-virtual-fashion-js" */),
  "component---src-pages-virtual-popup-store-js": () => import("./../../../src/pages/virtual-popup-store.js" /* webpackChunkName: "component---src-pages-virtual-popup-store-js" */),
  "component---src-pages-virtual-showroom-js": () => import("./../../../src/pages/virtual-showroom.js" /* webpackChunkName: "component---src-pages-virtual-showroom-js" */)
}

